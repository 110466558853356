<template>
  <div class="demo-breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
          v-for="(o, index) of breadcrumbItem"
          :key="index"><span>{{ o.name }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>

</template>

<script>
export default {
  name: "demo-breadcrumb",
  mounted() {
    let current = this.$router.history.current;
    if (current) {
      this.breadcrumbItem = []
      this.$store.state.defaultActive = current.meta.index
      let arr = current.meta.title.split('/');
      for (const arrElement of arr) {
        this.breadcrumbItem.push({name: arrElement})
      }
    }
  },
  watch:{
    $route(val) {
      if (val.meta.title) {
        this.breadcrumbItem = []
        let arr = val.meta.title.split('/');
        for (const arrElement of arr) {
          this.breadcrumbItem.push({name: arrElement})
        }
      }
    }
  },
  data(){
    return{
      breadcrumbItem:[{name: 'Dashboard'},{name: '主控台'}]
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/css/themeify.scss';

.demo-breadcrumb{
  display: inline-block;
  padding: 0 5px;
  span {
    @include themeify{
      color: themed('header-text-color');
    }
  }
}

</style>