<template>
   <div class="index">
      <el-container class="container" :direction="$store.state.container === 1?'horizontal':'vertical'">
         <el-aside width="auto" class="aside-shadow" v-if="$store.state.container === 1">
            <v-aside></v-aside>
         </el-aside>
         <el-header class="header" v-if="$store.state.container === 2">
            <v-header></v-header>
         </el-header>
         <el-container :direction="$store.state.container === 1? 'vertical':'horizontal'" :style="{height: $store.state.container === 1? '100%':'calc(100% - 60px)'}">
            <el-header class="header" v-if="$store.state.container === 1">
               <v-header></v-header>
            </el-header>
            <el-aside width="auto" class="aside-shadow" v-if="$store.state.container === 2">
               <v-aside></v-aside>
            </el-aside>
            <el-main class="main">
               <el-collapse-transition>
                  <v-fast-nav v-show="!$store.state.fastNav" @removeTabs="removeKeeps"></v-fast-nav>
               </el-collapse-transition>
               <el-card ref='mainPageContent' class="main-card" :body-style="{padding:'10px'}" shadow="never" :style="{height: $store.state.fastNav? 'calc(100% - 30px)':'100%', borderTop: $store.state.fastNav? 'none':''}">
                  <!-- <keep-alive :include='list'> -->
                  <keep-box ref="keepBox" />
                  <!-- </keep-alive> -->
                  <el-backtop target=".main-card"></el-backtop>
               </el-card>
            </el-main>
         </el-container>
      </el-container>
      <v-drawer></v-drawer>
      <el-backtop></el-backtop>
   </div>

</template>

<script>
import vAside from '@/components/assembly-aside'
import vHeader from '@/components/assembly-header'
import vFastNav from '@/components/assembly-fast-nav'
import vDrawer from '@/components/assembly-drawer'
import keepBox from "./keep-box";
import store from "@/vuex";

export default {
   name: "index",
   components: {
      vHeader,
      vAside,
      vFastNav,
      vDrawer,
      keepBox
   },
   created() {
      //初始化系统参数赋值
      this.parameterlist();
   },
   computed: {
      list() {
         return this.$store.state.routerList
      }
   },
   methods: {
      removeKeeps(arr) {
         this.$refs.keepBox.removeKeep(arr)
      },
      //查询
      parameterlist() {
         let userId = JSON.parse(sessionStorage.getItem("userInfo")).sysUiId;
         this.$server
            .parameterlist({ userId: userId })
            .then((res) => {
               if (res.ok) {
                  let {
                     sysPiFastNavidation: fastNav,
                     sysPiHeadNavidation: isHeader,
                     sysPiLayout: container,
                     sysPiLeftNavidation: isCollapse,
                     sysPiModalClose: closeModelWithShadow,
                     sysPiModalTips: closeModel,
                     sysPiTheme: globalTheme,
                     sysPiRows: initPageSize,
                  } = res.data;
                  const themeOption = ["default", "dark", "_blue"];
                  this.$store.state.fastNav = fastNav;
                  this.$store.state.isHeader = isHeader;
                  this.$store.state.container = container;
                  this.$store.state.isCollapse = isCollapse;
                  this.$store.state.closeModelWithShadow =
                     closeModelWithShadow === 0 ? false : true;

                  this.$store.state.closeModel = closeModel === 0 ? true : false;
                  this.$store.state.globalTheme = themeOption[parseInt(globalTheme)];
                  this.$store.state.initPageSize = !initPageSize ? 10 : initPageSize;
                  console.log(this.$store.state.isHeader, this.$store.state.fastNav)
               } else {
                  this.$message.error(res.msg);
               }
            })
            .catch((e) => {
               console.log(e);
            });
      },
   },
}
</script>

<style lang="scss">
@import "~@/assets/css/themeify.scss";

.index {
   height: 100%;
   overflow-y: hidden;
   .container {
      height: 100%;
   }

   .header {
      padding: 0;
      height: auto !important;
      position: relative;
      border-bottom: 1px solid;
      border-color: #e6e6e6;
      @include themeify {
         background-color: themed("header-bg-color");
         color: themed("header-text-color");
         border-color: themed("header-bd-color");
      }
   }

   .main {
      padding: 8px;
      overflow: hidden;
      background-color: #f2f6fc;
   }

   .main-card {
      border-radius: 0px;
      overflow-y: auto;
   }
   .el-dialog {
      .el-dialog__header {
         border-bottom: 1px solid;
         @include themeify {
            background-color: themed("header-bg-color");
            border-color: themed("header-bd-color");
            color: themed("header-text-color");
         }
         span,
         i {
            @include themeify {
               color: themed("header-text-color");
            }
         }
      }
      .el-dialog__footer {
         border-top: 1px solid #e6e6e6;
         background-color: #f5f7fa;
      }
   }
}
</style>
