<template>
<!--  :include="include"-->
    <keep-alive>
      <router-view  :key="$route.name"></router-view>
    </keep-alive>
</template>

<script>
export default {
  data(){
    return {
      include:[]
    }
  },
  methods:{
    removeKeep(arr){
      const cache = this._vnode.componentInstance.cache;
      for(let a = 0; a < arr.length; a++){
        if(cache[arr[a].key]){
          cache[arr[a].key].componentInstance.$destroy()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
