<template>
  <div :class="searchSelect">
    <i class="iconfont icon-search"></i>
    <el-select
        popper-class="select-option"
        v-model="value"
        filterable
        remote
        reserve-keyword
        placeholder="请输入关键词"
        :remote-method="remoteMethod"
        :loading="loading">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "search-select",
  watch:{
    value(){
      if (this.value !== ''){
        this.$router.push({path: this.value},onComplete => {},onAbort => {})
      }
      this.value = ''
      this.options = []
    }
  },
  data() {
    return {
      options: [],
      value: [],
      list: [],
      loading: false,
      searchSelect: this.$store.state.container === 1? 'search-select':'search-select1',
    }
  },
  mounted() {
    this.list = this.$router.options.routes[0].children.map(item => {
      return {value: item.path, label: item.meta.title}
    })
  },
  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter(item => {
            return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/css/themeify.scss';

.search-select{
  @include themeify{
    color: themed('header-text-color');
  }
  .el-input__inner{
    padding-left: 5px;
    border: none;
    background-color: transparent;
    @include themeify{
      color: themed('header-text-color');
    }
  }
}
.search-select1{
  @include themeify{
    color: themed('aside-text-color');
  }
  .el-input__inner{
    padding-left: 5px;
    border: none;
    border-bottom: 1px;
    background-color: transparent;
    @include themeify{
      color: themed('aside-text-color');
    }
  }
}
.select-option{
  .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
    background-color: #EBEEF5;
  }
}
</style>