<template>
   <!--  noSelect：禁止选中文字-->
   <div class="assembly-header noSelect">
      <!--    展开收起动画-->
      <el-collapse-transition>
         <!--      展开收起开关-->
         <div v-show="$store.state.isHeader === 0">

            <!--**** logo *******************************************************************************************************-->
            <!--        上下布局时显示顶部logo，_blue主题logo采用白色-->
            <div v-show="$store.state.container === 2" class="logo">
               <img :src="($store.state.globalTheme === '_blue')?require('@/assets/logo_2.png'):require('@/assets/logo.png')" />
               <div>{{$store.state.name}}</div>
            </div>

            <!--**** 侧边栏开关和面包屑导航 *****************************************************************************************-->
            <!--        侧边菜单开关和面包屑导航只有左右布局显示-->
            <div style="float: left" v-show="$store.state.container === 1">
               <div class="menu-item" @click="changeCollapse">
                  <i :class="$store.state.isCollapse?'iconfont icon-indent':'iconfont icon-outdent'"></i>
               </div>
               <div class="menu-item hover-none">
                  <breadcrumb></breadcrumb>
               </div>
            </div>

            <!--**** 顶部右侧菜单导航 *****************************************************************************************-->
            <!--        导航搜索（左右布局显示），颜色选择器，放大，字体大小，消息，个人中心，更多-->
            <div style="float: right">
               <div class="menu-item hover-none" v-show="$store.state.container === 1">
                  <search-select style="display: inline-block;"></search-select>
               </div>
               <!-- <div class="menu-item" v-if="reFresh">
            <theme-assembly></theme-assembly>
          </div> -->
               <div class="menu-item" @click="changeScreen">
                  <i :class="fullscreen?'iconfont icon-compress-alt':'iconfont icon-expand-alt'"></i>
               </div>
               <div class="menu-item my-dropdown">
                  <el-dropdown>
                     <span class="el-dropdown-link">
                        <i class="iconfont icon-ziti"></i>
                     </span>
                     <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="changeSize('')">默认</el-dropdown-item>
                        <el-dropdown-item @click.native="changeSize('medium')">中等</el-dropdown-item>
                        <el-dropdown-item @click.native="changeSize('small')">小型</el-dropdown-item>
                        <el-dropdown-item @click.native="changeSize('mini')">超小</el-dropdown-item>
                     </el-dropdown-menu>
                  </el-dropdown>
               </div>
               <!-- <div class="menu-item my-dropdown">
            <el-dropdown placement="bottom">
              <span class="el-dropdown-link">
                <el-badge :value="3" class="badge">
                  <i class="iconfont icon-bell"></i>
                </el-badge>
              </span>
              <el-dropdown-menu slot="dropdown">
                <bell></bell>
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
               <div class="menu-item my-dropdown">
                  <el-dropdown>
                     <span class="el-dropdown-link">
                        <img height="20px" width="20px" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
                        <i style="margin-left: 3px" class="el-icon-caret-bottom"></i>
                     </span>
                     <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item icon="el-icon-user" @click.native="openPerson">个人中心</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-lock" @click.native="changePassword">修改密码</el-dropdown-item>
                        <el-dropdown-item divided icon="el-icon-right" @click.native="logOut">退出登录</el-dropdown-item>
                     </el-dropdown-menu>
                  </el-dropdown>
               </div>
               <div class="menu-item" @click="$store.state.drawer = true" v-if="$store.state.userVisible ">
                  <i class="iconfont icon-ellipsis-vertical"></i>
               </div>
            </div>
         </div>
      </el-collapse-transition>
      <!-- 杨帆 TODO -->
      <!-- <el-button @click="$store.state.isHeader = !$store.state.isHeader"
               :icon="!$store.state.isHeader?'el-icon-arrow-down':'el-icon-arrow-up'" class="header-btn"></el-button> -->
      <!--    个人中心模态框-->
      <el-dialog title="个人中心" :visible.sync="dialogVisiblePerson" width="60%">
         <personal></personal>
      </el-dialog>
      <!--    修改密码模态框   -->
      <assembly-dialog title="修改密码" :visible.sync="dialogPassword" width="30%" :close-on-click-modal="$store.state.closeModelWithShadow" :close-on-press-escape="$store.state.closeModelWithShadow" @before-close="passwordClose">
         <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm" label-width="80px">
            <el-form-item label="原始密码" prop="oldPassword">
               <el-input v-model="passwordForm.oldPassword" :size="$store.state.size" placeholder="请输入原始密码" clearable></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
               <el-input v-model="passwordForm.password" :size="$store.state.size" placeholder="请输入新密码" clearable></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="password1">
               <el-input v-model="passwordForm.password1" :size="$store.state.size" placeholder="请输入确认密码" clearable></el-input>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearForm('passwordForm')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="savePasswordSubmit('passwordForm')">确 定</el-button>
         </span>
      </assembly-dialog>
   </div>
</template>

<script>
import breadcrumb from '@/components/assembly-breadcrumb';
import themeAssembly from '@/components/assembly-theme'
import bell from '@/components/assembly-header/bell'
import searchSelect from '@/components/assembly-header/search-select'
import personal from '@/components/assembly-header/personal'

import screenFull from "screenfull";
import AssemblyDialog from "components/assembly-dialog";

export default {
   name: "assembly-header",
   watch: {
      '$store.state.container'() {
         this.reFresh = false
         this.$nextTick(() => {
            this.reFresh = true
         })
      }
   },
   components: {
      AssemblyDialog,
      breadcrumb,
      // themeAssembly,
      // bell,
      searchSelect,
      personal
   },
   data() {
      return {
         fullscreen: false,
         reFresh: true,
         dialogVisiblePerson: false,
         dialogPassword: false,
         passwordForm: {
            id: '',
            oldPassword: '',
            password: '',
            password1: ''
         },
         passwordRules: {
            oldPassword: [
               { required: true, validator: this.$verification.validateOldPassWord, trigger: 'blur' }
            ],
            password: [
               { required: true, validator: this.$verification.validatePassWord, trigger: 'blur' }
            ],
            password1: [
               { required: true, validator: this.$verification.validateConfirmPassword, trigger: 'blur' }
            ],
         },
      };
   },
   mounted() {
      const that = this
      window.addEventListener('resize', () => {
         if (!that.checkFull()) {
            // 退出全屏后要执行的动作
            console.log("退出全屏")
            that.fullscreen = false
         }
      })
   },

   methods: {
      checkFull() {
         console.log('全屏3', document.webkitIsFullScreen)

         var isFull = document.webkitIsFullScreen;

         //to fix : false || undefined == undefined
         if (isFull === undefined) isFull = false;
         return isFull;
      },
      changeCollapse() {
         this.$store.state.isCollapse = this.$store.state.isCollapse === 0 ? 1 : 0
      },
      //改变全屏
      changeScreen() {
         if (!screenFull.isEnabled) {
            // 此时全屏不可用
            this.$message.warning('此时全屏组件不可用')
            return
         }
         // document.documentElement.requestFullscreen()  原生js调用
         //   如果可用 就可以全屏
         screenFull.toggle()
         this.fullscreen = !this.fullscreen
      },
      //改变布局尺寸
      changeSize(size) {
         this.$store.state.size = size
      },
      //个人中心模态框
      openPerson() {
         console.log('=========', JSON.stringify(JSON.parse(sessionStorage.getItem('userInfo'))))
         this.dialogVisiblePerson = !this.dialogVisiblePerson
      },
      //  退出登录
      logOut() {
         this.$confirm('确认要退出登录吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            this.$server.logout().then((res) => {
               if (res.ok) {
                  sessionStorage.clear()
                  localStorage.clear()
                  this.$message.success(res.msg);
                  let that = this;
                  setTimeout(function () {
                     that.$router.push('login')
                  }, 300)
               } else {
                  this.$message.warning(res.msg)
               }
            })
         }).catch(() => {
         });
      },
      //修改密码保存提交
      savePasswordSubmit(formName) {
         console.log('修改密码保存提交', JSON.stringify(this.passwordForm))
         if (this.passwordForm.password != this.passwordForm.password1) {
            this.$message.warning('两次输入新密码不一致')
            return
         }
         this.passwordForm.id = JSON.parse(sessionStorage.getItem('userInfo')).sysUiId,//用户id
            this.$refs[formName].validate((valid) => {
               if (valid) {
                  this.$server.changePassword(this.passwordForm).then(res => {
                     if (res.ok) {
                        this.$message.success(res.msg)
                        this.passwordClose()
                        this.$common.clearFormValue(this.passwordForm)
                        this.clearForm('passwordForm')
                     } else {
                        this.$message.error(res.msg)
                     }
                  }).catch(e => {
                     console.log(e)
                  })
               } else {
                  console.log('error submit!!');
                  return false;
               }
            });
      },
      //修改密码
      changePassword() {
         this.dialogPassword = true
      },
      passwordClose() {
         this.dialogPassword = false
      },
      //清空表单
      clearForm(passwordForm) {
         this.$refs[passwordForm].resetFields();
         this.$common.clearFormValue(this.passwordForm)
      },
   }
}
</script>

<style lang="scss">
@import "~@/assets/css/themeify.scss";

.assembly-header {
   //展开收起菜单按钮
   .header-btn {
      position: absolute;
      bottom: -16px;
      left: 50%;
      z-index: 1998;
      padding: 0 10px;
      border-radius: 0 0 10px 10px;
      border-top: none;
   }

   .logo {
      float: left;
   }

   .menu-item {
      display: inline-block;
      padding: 0 12px;
      height: 59px;
      line-height: 59px;

      &:not(.hover-none):hover {
         cursor: pointer;
         @include themeify {
            background-color: themed("header-hover-color");
         }
      }

      i {
         font-size: 18px;
      }

      .badge {
         line-height: 20px;
         @include themeify {
            color: themed("header-text-color");
         }
      }

      .el-dropdown-link {
         padding: 20px 12px;
         cursor: pointer;
         @include themeify {
            color: themed("header-text-color");
         }

         img {
            vertical-align: sub;
            cursor: pointer;
         }
      }

      .el-badge__content {
         border: none;
      }
   }

   .my-dropdown {
      padding: 0;
   }
}
</style>
