<template>
   <!--  noSelect：禁止选中文字-->
   <div class="demo-aside noSelect">

      <!--**** logo *******************************************************************************************************-->
      <!--    左右布局时显示侧边logo-->
      <div v-show="$store.state.container === 1" class="logo">
         <img :src="require('@/assets/logo.png')" />
         <div v-show="logoText" :style="{color: $store.state.globalTheme === '_blue'? '#000000':''}">{{$store.state.name}}</div>
      </div>

      <!--**** 侧边菜单开关 *************************************************************************************************-->
      <!--    上下布局时显示菜单顶部的开关和搜索导航-->
      <div v-show="$store.state.container === 2" class="collapse-style">
         <search-select style="display: inline-block;" v-show="search"></search-select>
         <i @click="changeCollapse" :class="$store.state.isCollapse?'iconfont icon-indent':'iconfont icon-outdent'"></i>
      </div>

      <!--**** 侧边菜单 *************************************************************************************************-->
      <!--    布局变化时菜单高度会变化，导致滚动条高度需要变化，暂时处理方法时重新刷新菜单-->
      <el-scrollbar :class="scrollbarHeight" v-if="reFresh">
         <el-menu :default-active="$store.state.defaultActive" :collapse="$store.state.isCollapse === 1 ? true : false" unique-opened>
            <el-menu-item v-if="hasPermission('1')" index="1" @click="jump('console')">
               <i style="padding-left: 0" class="iconfont icon-terminal-fill"></i>
               <span>主控台</span>
            </el-menu-item>
            <el-submenu v-if="hasPermission('2')" index="2">
               <template slot="title">
                  <i class="front-i iconfont icon-cog-fill"></i>
                  <span>系统管理</span>
               </template>
               <el-menu-item v-if="hasPermission('2-1')" index="2-1" @click="jump('customer')"><span>客户管理</span></el-menu-item>
               <el-menu-item v-if="hasPermission('2-2')" index="2-2" @click="jump('role')"><span>角色管理</span></el-menu-item>
               <!-- <el-menu-item v-if="hasPermission('2-3')" index="2-3" @click="jump('applet')"><span>小程序配置</span></el-menu-item> -->
               <!-- <el-menu-item v-if="hasPermission('2-4')" index="2-4" @click="jump('shortMessage')"><span>短信平台管理</span></el-menu-item> -->
               <el-menu-item v-if="hasPermission('2-5')" index="2-5" @click="jump('personnel')"><span>人员管理</span></el-menu-item>
               <!-- <el-menu-item v-if="hasPermission('2-6')" index="2-6" @click="jump('log')"><span>日志管理</span></el-menu-item> -->
               <el-menu-item v-if="hasPermission('2-7')" index="2-7" @click="jump('interface')"><span>接口维护</span></el-menu-item>
               <el-menu-item v-if="hasPermission('2-8')" index="2-8" @click="jump('systemParameter')"><span>系统参数</span></el-menu-item>
               <el-menu-item v-if="hasPermission('2-9')" index="2-9" @click="jump('dropDownData')"><span>数据字典</span></el-menu-item>

            </el-submenu>
            <el-submenu v-if="hasPermission('3')" index="3">
               <template slot="title">
                  <i class="front-i iconfont icon-shopping-cart-fill"></i>
                  <span>商品管理</span>
               </template>
               <el-menu-item v-if="hasPermission('3-3')" index="3-3" @click="jump('brandName')"><span>品牌维护</span></el-menu-item>
               <!-- <el-menu-item v-if="hasPermission('3-1')" index="3-1" @click="jump('productName')"><span>品种维护</span></el-menu-item> -->
               <el-menu-item v-if="hasPermission('3-1')" index="3-1" @click="jump('productName')"><span>品种维护</span></el-menu-item>
               <el-menu-item v-if="hasPermission('3-2')" index="3-2" @click="jump('contactLens')"><span>商品维护</span></el-menu-item>
            </el-submenu>
            <el-submenu v-if="hasPermission('4')" index="4">
               <template slot="title">
                  <i class="front-i iconfont icon-commentlines-fill"></i>
                  <span>订单管理</span>
               </template>
               <el-menu-item v-if="hasPermission('4-1')" index="4-1" @click="jump('order')"><span>订单管理</span></el-menu-item>
            </el-submenu>
            <el-submenu v-if="hasPermission('5')" index="5">
               <template slot="title">
                  <i class="front-i iconfont icon-resource"></i>
                  <span>加工参数</span>
               </template>
               <el-menu-item v-if="hasPermission('5-1')" index="5-1" @click="jump('basicProcessing')"><span>基础加工参数维护</span></el-menu-item>
            </el-submenu>
            <!-- 邮件解析相关菜单页面 - 后期可能会更改权限，现在用的是加工参数的权限  YFTODO -->
            <el-submenu v-if="hasPermission('6')" index="6">
               <template slot="title">
                  <!-- <i class="el-icon-message"></i> -->
                  <i class="icon-mail-fill front-i iconfont"></i>
                  <!-- <img :src="emailPNG" alt="" style="width:16px;height:17px;margin-right:8px"> -->
                  <span>邮件管理</span>
               </template>
               <el-menu-item v-if="hasPermission('6-1')" index="8-1" @click="jump('email')"><span>邮件解析规则</span></el-menu-item>
               <!-- <el-menu-item v-if="hasPermission('6-3')" index="8-3" @click="jump('emailGoods')"><span>邮件解析商品</span></el-menu-item> -->
               <el-menu-item v-if="hasPermission('6-2')" index="8-2" @click="jump('emailLogs')"><span>邮件解析日志</span></el-menu-item>
            </el-submenu>
            <!--        <el-submenu index="6">-->
            <!--          <template slot="title">-->
            <!--            <i class="front-i iconfont icon-commentdots-fill"></i>-->
            <!--            <span>消息管理</span>-->
            <!--          </template>-->
            <!--          <el-menu-item index="6-1" @click="jump('earlyWarning')"><span>预警管理</span></el-menu-item>-->
            <!--          <el-menu-item index="6-2" @click="jump('orderReminder')"><span>订单提醒</span></el-menu-item>-->
            <!--          <el-menu-item index="6-3" @click="jump('manufacturerAdvertising')"><span>厂家广告</span></el-menu-item>-->
            <!--        </el-submenu>-->
            <!--        <el-submenu index="7">-->
            <!--          <template slot="title">-->
            <!--            <i class="front-i iconfont icon-layergroup-fill"></i>-->
            <!--            <span>库存管理</span>-->
            <!--          </template>-->
            <!--          <el-menu-item index="7-1" @click="jump('inventoryView')"><span>库存查看</span></el-menu-item>-->
            <!--        </el-submenu>-->
            <!--        <el-submenu index="8">-->
            <!--          <template slot="title">-->
            <!--            <i class="front-i iconfont icon-borderbottom-fill"></i>-->
            <!--            <span>报表中心</span>-->
            <!--          </template>-->
            <!--        </el-submenu>-->
         </el-menu>
      </el-scrollbar>
   </div>

</template>

<script>
import searchSelect from "@/components/assembly-header/search-select";
export default {
   name: "demo-aside",
   watch: {
      /**
       * 路由跳转反选菜单
       * @param val
       */
      $route(val) {
         if (val.meta) {
            this.$store.state.defaultActive = val.meta.index;
         }
      },
      /**
       * 菜单开关文字logo文字延迟显示
       */
      "$store.state.isCollapse"(val) {
         if (val == 0 && !this.logoText) {
            setTimeout(() => {
               this.logoText = !this.logoText;
               this.search = !this.search;
            }, 150);
         } else if (val != 0 && this.logoText) {
            this.logoText = !this.logoText;
            this.search = !this.search;
         }
      },
      /**
       * 监听头部导航开关调整侧边导航高度
       */
      "$store.state.isHeader"() {
         if (this.$store.state.container === 2) {
            if (this.$store.state.isHeader) {
               this.scrollbarHeight =
                  this.$store.state.container === 1
                     ? "scrollbarHeight-60"
                     : "scrollbarHeight-110";
            } else {
               this.scrollbarHeight =
                  this.$store.state.container === 1
                     ? "scrollbarHeight-0"
                     : "scrollbarHeight-50";
            }
            this.reFresh = false;
            this.$nextTick(() => {
               this.reFresh = true;
            });
         }
      },
   },
   components: {
      searchSelect,
   },
   data() {
      return {
         emailPNG: require("../../assets/email.png"),
         logoText: !this.$store.state.isCollapse,
         search: !this.$store.state.isCollapse,
         reFresh: true,
         scrollbarHeight:
            this.$store.state.container === 1
               ? "scrollbarHeight-60"
               : "scrollbarHeight-110",
      };
   },
   methods: {
      //判断权限是否存在
      hasPermission(item) {
         return this.$common.hasPermission(item);
      },
      jump: function (path) {
         this.$store.state.currentRouterState = 1;
         // try{
         //   const i = this.$store.state.routerList.findIndex(item=>item == path.replace('/',''))
         //   console.log(i)
         //   this.$store.state.routerList.splice(i,0)
         // }catch{}
         this.$router.push(
            { path: path },
            (onComplete) => { },
            (onAbort) => { }
         );
      },
      changeCollapse() {
         this.$store.state.isCollapse = this.$store.state.isCollapse === 1 ? 0 : 1;
      },
   },
};
</script>

<style lang="scss">
@import "~@/assets/css/themeify.scss";

.demo-aside {
   height: 100%;
   overflow: hidden;
   border-right: 1px solid;
   border-color: #e6e6e6;
   @include themeify {
      background-color: themed("aside-bg-color");
      border-color: themed("aside-bd-color");
   }

   .collapse-style {
      height: 50px;
      line-height: 50px;
      font-size: 24px;
      text-align: right;
      border-bottom: 1px solid;
      @include themeify {
         color: themed("aside-text-color");
         border-color: themed("aside-bd-color");
      }

      i {
         padding: 20px 20px 14px 28px;
         cursor: pointer;
      }

      .el-select {
         width: 132px;
      }
   }

   .scrollbarHeight-0 {
      height: 100%;
   }

   .scrollbarHeight-50 {
      height: calc(100% - 50px);
   }

   .scrollbarHeight-60 {
      height: calc(100% - 60px);
   }

   .scrollbarHeight-110 {
      height: calc(100% - 110px);
   }

   .el-menu {
      border: none;
      @include themeify {
         background-color: themed("aside-bg-color");
      }

      i {
         @include themeify {
            color: themed("aside-text-color");
         }
      }

      &:not(.el-menu--collapse) {
         width: 260px;
      }

      .el-submenu {
         ul {
            @include themeify {
               background-color: themed("aside-open-color");
            }
         }

         .el-submenu__title {
            @include themeify {
               color: themed("aside-text-color");
            }

            &:hover {
               @include themeify {
                  background-color: themed("aside-hover-color");
               }
            }
         }

         .el-submenu__title {
            .front-i {
               padding-right: 8px;
            }
         }
      }

      .el-menu-item {
         background-color: transparent;
         i {
            padding: 8px;
         }

         @include themeify {
            color: themed("aside-text-color");
         }

         &:hover {
            @include themeify {
               background-color: themed("aside-hover-color");
            }
         }
      }

      .is-active {
         &:not(.el-submenu) {
            @include themeify {
               background-color: themed("aside-active-color");
               color: themed("aside-active-text-color");
            }
         }
      }
   }

   .horizontal-collapse-transition {
      transition: 150ms width ease-in-out, 150ms padding-left ease-in-out,
         150ms padding-right ease-in-out;
   }

   .logo {
      border-bottom: 1px solid;
      border-color: #e6e6e6;
      @include themeify {
         border-color: themed("aside-bd-color");
      }
   }
}
</style>
