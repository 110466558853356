<template>
  <div class="bell">
    <el-tabs>
      <div class="tabs-line"></div>
      <el-tab-pane>
        <span class="pane-item" slot="label">通知<div>(<span>99+</span>)</div></span>
        <div class="pane-content">
          <ul class="list">
            <li v-for="(item, index) in notices" :key="index" class="list-item">
              <el-row>
                <el-col :span="5">
                  <div style="text-align: center">
                    <img class="list-img" width="45" height="45" :src="item.pic"/>
                  </div>
                </el-col>
                <el-col :span="19">
                  <div class="item-title">{{ item.title }}</div>
                  <div class="item-time">{{ item.time }}</div>
                </el-col>
              </el-row>
            </li>
          </ul>
        </div>
      </el-tab-pane>
      <el-tab-pane>
        <span class="pane-item" slot="label">关注<div>(<span>0</span>)</div></span>
        <div class="no-data">暂无数据</div>
      </el-tab-pane>
      <el-tab-pane>
        <span class="pane-item" slot="label">代办<div>(<span>0</span>)</div></span>
        <div class="no-data">暂无数据</div>
      </el-tab-pane>
    </el-tabs>

    <div class="footer">
      <el-link :underline="false" type="primary">
        <el-icon class="el-icon-delete"></el-icon>
        清空通知
      </el-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "bell",
  data() {
    return {
      notices: [
        {
          pic: 'https://img1.baidu.com/it/u=3851407232,614200599&fm=11&fmt=auto&gp=0.jpg',
          title: '蒂姆·库克回复了你的邮件',
          time: '2019-05-08 14:33:18'
        },
        {
          pic: 'https://img2.baidu.com/it/u=3256750006,1716423677&fm=26&fmt=auto&gp=0.jpg',
          title: '乔纳森·伊夫邀请你参加会议',
          time: '2019-05-08 14:33:18'
        },
        {
          pic: 'https://img1.baidu.com/it/u=2319207455,718702078&fm=253&fmt=auto&app=120&f=JPG?w=200&h=200',
          title: '斯蒂夫·沃兹尼亚克已批准了你的休假申请',
          time: '2019-05-08 14:33:18'
        },
        {
          pic: 'https://img1.baidu.com/it/u=2017674304,3966199274&fm=253&fmt=auto&app=120&f=JPEG?w=200&h=200',
          title: '史蒂夫·乔布斯收藏了你的文章',
          time: '2019-05-08 14:33:18'
        },
        {
          pic: 'https://img2.baidu.com/it/u=2691017588,3409825008&fm=253&fmt=auto&app=120&f=JPEG?w=200&h=200',
          title: '比尔·费尔南德斯通过了你的好',
          time: '2019-05-08 14:33:18'
        },
      ]
    }
  }
}
</script>

<style lang="scss">
.bell {
  width: 350px;

  .el-tabs__header {
    margin: 0
  }

  .tabs-line {
    width: 350px;
    height: 2px;
    background-color: #e6e6e6;
    position: fixed;
    margin-top: -2px;
  }

  .list {
    height: 300px;
    padding-left: 0;

    .list-item {
      list-style: none !important;
      padding: 12px 24px;
      border-bottom: 1px solid #e6e6e6;

      &:hover {
        background-color: #ecf5ff;
      }

      .item-title {
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .item-time {
        height: 15px;
        line-height: 15px;
        font-size: 12px;
        color: #808695;
      }

      .list-img {
        border-radius: 50%;
        border: 1px solid #e6e6e6;
        vertical-align: middle;
      }
    }
  }


  .footer {
    border-top: 2px solid #e4e7ed;
    padding: 5px 0;
    text-align: center;
  }

  .pane-item {
    width: 64px;
    margin: 0 auto;

    div {
      width: 37px;
      display: inline-block;
    }

    span {
      color: #F56C6C;
    }
  }

  .pane-content {
    max-height: 300px;
    overflow: auto
  }

  .no-data {
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: #C0C4CC
  }


  .el-tabs__nav-wrap {
    margin: 0 37px;
  }
}

</style>