<template>
  <div class="personal">
    <div class="title">
      <span>我的账户</span>
    </div>
    <div class="info">
      <img src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"/>
      <div>
        <span class="span-margin-bottom"><i class="el-icon-phone-outline"></i>手机号：{{userInfo.sysUiTel?userInfo.sysUiTel:'未绑定'}}
<!--          <el-button type="text">绑定</el-button>-->
        </span>
        <span class="span-margin-bottom"><i class="el-icon-lock"></i>昵&nbsp;&nbsp;&nbsp;称：{{userInfo.username?userInfo.username:'未设置'}}
<!--          <el-button type="text">设置</el-button>-->
        </span>
      </div>
    </div>
    <div class="title">
      <span>我的企业</span>
    </div>
    <div class="enterprise">
      <el-descriptions :colon="false" :column="4" direction="vertical">
        <el-descriptions-item label="企业" label-class-name="my-label"><i class="el-icon-collection-tag"></i>{{userInfo.customer}}
        </el-descriptions-item>
        <el-descriptions-item label="角色" label-class-name="my-label">{{userInfo.roleName}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  name: "personal",
  data() {
    return {
      userInfo:JSON.parse(sessionStorage.getItem('userInfo'))
    }
  },
}
</script>

<style lang="scss">
@import '~@/assets/css/themeify.scss';

.personal {
  margin: 20px;
  .span-margin-bottom{
    margin-bottom:5px;
  }
  //个人中心样式
  .title {
    border-left: 5px solid;
    border-radius: 3px;
    margin: 20px 0;
    @include themeify {
      border-color: themed('person-title-color');
    }

    span {
      padding-left: 10px;
      font-size: 16px;
    }
  }

  .info {
    img {
      height: 60px;
      width: 60px;
      vertical-align: middle;
    }

    div {
      display: inline-block;
      vertical-align: bottom;

      span {
        font-size: 12px;
        display: block;
        margin-left: 15px;

        .el-button {
          padding: 5px;
        }
      }
    }
  }
  .enterprise{
    .my-label {
      font-weight: 900 !important
    }
  }
}
</style>