<template>
  <div class="demo-drawer">
    <el-drawer
        size="300px"
        :visible.sync="$store.state.drawer">
      <div slot="title" :style="{color: $store.state.theme}"><i class="el-icon-setting"></i><strong>设置</strong></div>

      <el-divider content-position="left">布局设置</el-divider>
      <div class="mini-container">
        <div class="mini-container-div"
             :style="{borderColor:$store.state.container === 1?$store.state.theme:''}"
             @click="changeContainer(1)">
          <el-container>
            <el-aside></el-aside>
            <el-container>
              <el-header></el-header>
              <el-main></el-main>
            </el-container>
          </el-container>
        </div>
        <div class="mini-container-title" :style="{color:$store.state.container === 1?$store.state.theme:''}">左右布局</div>
      </div>
      <div class="mini-container">
        <div class="mini-container-div"
             :style="{borderColor:$store.state.container === 2?$store.state.theme:''}"
             @click="changeContainer(2)">
          <el-container>
            <el-header></el-header>
            <el-container>
              <el-aside></el-aside>
              <el-main></el-main>
            </el-container>
          </el-container>
        </div>
        <div class="mini-container-title" :style="{color:$store.state.container === 2?$store.state.theme:''}">上下布局</div>
      </div>
      <div style="clear: both"></div>

      <el-divider content-position="left">主题设置</el-divider>
      <div class="mini-container">
        <div class="mini-container-div"
             :style="{borderColor:$store.state.globalTheme === 'default'?$store.state.theme:''}"
             @click="changeContainerTheme('default')">
          <el-container>
            <el-aside style="background-color: white"></el-aside>
            <el-container>
              <el-header style="background-color: white"></el-header>
              <el-main></el-main>
            </el-container>
          </el-container>
        </div>
        <div class="mini-container-title" :style="{color:$store.state.globalTheme === 'default'?$store.state.theme:''}">
          浅色
        </div>
      </div>
      <div class="mini-container">
        <div class="mini-container-div"
             :style="{borderColor:$store.state.globalTheme === 'dark'?$store.state.theme:''}"
             @click="changeContainerTheme('dark')">
          <el-container>
            <el-aside style="background-color: #191a23"></el-aside>
            <el-container>
              <el-header style="background-color: #191a23"></el-header>
              <el-main></el-main>
            </el-container>
          </el-container>
        </div>
        <div class="mini-container-title" :style="{color:$store.state.globalTheme === 'dark'?$store.state.theme:''}">深色
        </div>
      </div>
      <div class="mini-container">
        <div class="mini-container-div"
             :style="{borderColor:$store.state.globalTheme === '_blue'?$store.state.theme:''}"
             @click="changeContainerTheme('_blue')">
          <el-container>
            <el-header style="background-color: #0A57BE"></el-header>
            <el-container>
              <el-aside></el-aside>
              <el-container>
                <el-main></el-main>
              </el-container>
            </el-container>
          </el-container>
        </div>
        <div class="mini-container-title" :style="{color:$store.state.globalTheme === '_blue'?$store.state.theme:''}">蓝色
        </div>
      </div>
      <div style="clear: both"></div>

      <el-divider content-position="left">基础设置</el-divider>
      <div class="drawer-item">
        <div style="float: left">
          <span>头部导航开关</span>
        </div>
        <div style="float: right">
          <el-switch v-model="$store.state.isHeader"
                     @click="$store.state.isHeader = !$store.state.isHeader"></el-switch>
        </div>
        <div style="clear: both"></div>
      </div>
      <div class="drawer-item">
        <div style="float: left">
          <label>侧边导航开关</label>
        </div>
        <div style="float: right">
          <el-switch v-model="$store.state.isCollapse"
                     @click="$store.state.isCollapse = !$store.state.isCollapse"></el-switch>
        </div>
        <div style="clear: both"></div>
      </div>
      <div class="drawer-item">
        <div style="float: left">
          <label>快捷导航开关</label>
        </div>
        <div style="float: right">
          <el-switch v-model="$store.state.fastNav"
                     @click="$store.state.fastNav = !$store.state.fastNav"></el-switch>
        </div>
        <div style="clear: both"></div>
      </div>
      <div class="drawer-item">
        <div style="float: left">
          <label>关闭模态框提示</label>
        </div>
        <div style="float: right">
          <el-switch v-model="$store.state.closeModel"
                     @click="$store.state.closeModel = !$store.state.closeModel"></el-switch>
        </div>
        <div style="clear: both"></div>
      </div>
      <div class="drawer-item">
        <div style="float: left">
          <label>点击弹窗外阴影关闭弹窗</label>
        </div>
        <div style="float: right">
          <el-switch v-model="$store.state.closeModelWithShadow"
                     @click="$store.state.closeModelWithShadow = !$store.state.closeModelWithShadow"></el-switch>
        </div>
        <div style="clear: both"></div>
      </div>
    </el-drawer>
  </div>
</template>

<script>

export default {
  name: "demo-drawer",
  methods: {
    changeContainer(type) {
      this.$store.state.container = type
      // sessionStorage.setItem('container', type);
    },
    changeContainerTheme(type) {
      this.$store.state.globalTheme = type
      // sessionStorage.setItem('globalTheme', type);
    }
  }
}
</script>

<style lang="scss">
.demo-drawer {
  .el-icon-setting {
    font-size: 20px;
    vertical-align: middle
  }

  .el-drawer__header {
    margin-bottom: 0;
  }

  .mini-container {
    margin: 10px;
    width: 80px;
    float: left;
    cursor: pointer;
    text-align: center;
    font-size: 12px;

    .mini-container-div {
      border: 1px solid #f0f0f0;

      .el-header {
        background-color: #B3C0D1;
        height: 10px !important;
      }

      .el-aside {
        background-color: #D3DCE6;
        width: 20px !important;
      }

      .el-main {
        background-color: #E9EEF3;
      }
    }

    .mini-container-title {
      padding-top: 2px;
    }
  }

  .drawer-item {
    padding: 5px 20px;
    font-size: 14px;
  }
}

</style>
