<template>
  <div class="theme-assembly">
    <el-color-picker
        v-model="theme"
        :predefine="['#409EFF', '#1890ff', '#304156','#212121','#11a983', '#13c2c2', '#6959CD', '#f5222d', ]"
        class="theme-picker"
        popper-class="theme-picker-dropdown"/>
  </div>

</template>

<script>
const version = require('element-ui/package.json').version // 从node_modules中获取element-ui 版本号
const ORIGINAL_THEME = '#409EFF' // 默认主题色

export default {
  data() {
    return {
      chalk: '', // content of assembly-theme-chalk css
      theme: '#409EFF'
    }
  },

  created() {
    if(localStorage.getItem('colorPicker')){
      this.theme = localStorage.getItem('colorPicker')
      this.$store.state.theme = localStorage.getItem('colorPicker')
    }
  },

  watch: {
    //  监听主题变更并编译主题
    async theme(val) {
      console.log('监听主题变更并编译主题', localStorage.getItem('colorPicker'))
      const oldVal = this.chalk ? this.theme : ORIGINAL_THEME

      if (typeof val !== 'string') return
      this.syssaveOrUpdate (val)

      localStorage.setItem('colorPicker',val)
      this.$store.state.theme = val
      //  获取新老主题色的色值集合
      const themeCluster = this.getThemeCluster(val.replace('#', ''))
      const originalCluster = this.getThemeCluster(oldVal.replace('#', ''))

      const $message = this.$message({
        message: '正在编译主题',
        type: 'success',
        duration: 0,
        iconClass: 'el-icon-loading'
      })
      // 将style渲染到DOM中
      const getHandler = (variable, id) => {
        return () => {
          const originalCluster = this.getThemeCluster(ORIGINAL_THEME.replace('#', ''))
          const newStyle = this.updateStyle(this[variable], originalCluster, themeCluster)
          let styleTag = document.getElementById(id)
          if (!styleTag) {
            styleTag = document.createElement('style')
            styleTag.setAttribute('id', id)
            document.head.appendChild(styleTag)
          }
          styleTag.innerText = newStyle
        }
      }
      //  初次进入或刷新时动态加载CSS文件
      if (!this.chalk) {
        const url = `https://unpkg.com/element-ui@${version}/lib/theme-chalk/index.css`
        await this.getCSSString(url, 'chalk')
      }
      const chalkHandler = getHandler('chalk', 'chalk-style')
      chalkHandler()

      const styles = [].slice.call(document.querySelectorAll('style')).filter(style => {
        const text = style.innerText
        return new RegExp(oldVal, 'i').test(text) && !/Chalk Variables/.test(text)
      })
      styles.forEach(style => {
        const { innerText } = style
        if (typeof innerText !== 'string') return
        style.innerText = this.updateStyle(innerText, originalCluster, themeCluster)
      })

      $message.close()
    }
  },

  methods: {
    //保存
    syssaveOrUpdate(row){
      let parameterAssignment = JSON.parse(sessionStorage.getItem('sysParameterInfo'))
      parameterAssignment.sysPiThemeColour = row
      parameterAssignment.sysPiUserId = JSON.parse(sessionStorage.getItem('userInfo')).sysUiId
          this.$server.syssaveOrUpdate(parameterAssignment).then(res =>{
          }).catch(e =>{
            console.log(e)
          })
    },
    //  更新主题色
    updateStyle(style, oldCluster, newCluster) {
      let newStyle = style
      oldCluster.forEach((color, index) => {
        newStyle = newStyle.replace(new RegExp(color, 'ig'), newCluster[index])
      })
      return newStyle
    },
    // 获取样式文件内容
    getCSSString(url, variable) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            this[variable] = xhr.responseText.replace(/@font-face{[^}]+}/, '')
            resolve()
          }
        }
        xhr.open('GET', url)
        xhr.send()
      })
    },
    // 获取主题同类色的集合
    getThemeCluster(theme) {
      const tintColor = (color, tint) => {
        let red = parseInt(color.slice(0, 2), 16)
        let green = parseInt(color.slice(2, 4), 16)
        let blue = parseInt(color.slice(4, 6), 16)
        if (tint === 0) { // when primary color is in its rgb space
          return [red, green, blue].join(',')
        } else {
          red += Math.round(tint * (255 - red))
          green += Math.round(tint * (255 - green))
          blue += Math.round(tint * (255 - blue))
          red = red.toString(16)
          green = green.toString(16)
          blue = blue.toString(16)
          return `#${red}${green}${blue}`
        }
      }
      const shadeColor = (color, shade) => {
        let red = parseInt(color.slice(0, 2), 16)
        let green = parseInt(color.slice(2, 4), 16)
        let blue = parseInt(color.slice(4, 6), 16)
        red = Math.round((1 - shade) * red)
        green = Math.round((1 - shade) * green)
        blue = Math.round((1 - shade) * blue)
        red = red.toString(16)
        green = green.toString(16)
        blue = blue.toString(16)
        return `#${red}${green}${blue}`
      }
      const clusters = [theme]
      for (let i = 0; i <= 9; i++) {
        clusters.push(tintColor(theme, Number((i / 10).toFixed(2))))
      }
      clusters.push(shadeColor(theme, 0.1))
      return clusters
    },
  }
}

</script>


<style lang="scss">
.theme-assembly{
  .theme-message,
  .theme-picker-dropdown {
    z-index: 99999;
    .el-color-dropdown__link-btn{
      display: none;
    }
  }
  .theme-picker{
    height: auto;
    vertical-align: middle;
    .el-color-picker__trigger{
      height: 26px;
      width: 26px;
      padding: 2px;
      border: none;
    }
    .el-color-picker__color{
      border: none;
    }
  }
}

</style>
