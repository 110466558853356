<template>
  <div class="demo-fast-nav">
    <el-row :gutter="5">

      <el-col :span="23">
        <el-tabs
        @tab-click='jump'
            ref="navTabs"
            v-model="tabsValue"
            type="card"
            closable
            @tab-remove="close($event, '_this')"
            @contextmenu.prevent.native="show($event)"
        >
          <el-tab-pane v-for="(item, index) in tabs" :key="index" :name="item.name">
            <span slot="label" :id="item.name">{{ item.title }}</span>
          </el-tab-pane>
        </el-tabs>
      </el-col>




      <el-col :span="1">
        <el-dropdown style="width: 100%" trigger="hover">
          <span class="el-dropdown-link">
            <el-icon class="el-icon-arrow-down nav-i"></el-icon>
          </span>
          <el-dropdown-menu ref="dropdown" slot="dropdown">
             <el-dropdown-item icon="el-icon-back" @click.native="close($event, 'left')">关闭左侧</el-dropdown-item>
             <el-dropdown-item icon="el-icon-right" @click.native="close($event, 'right')">关闭右侧</el-dropdown-item>
            <el-dropdown-item
                icon="el-icon-circle-close"
                @click.native="close($event, 'other')"
            >关闭其他</el-dropdown-item>
            <el-dropdown-item icon="el-icon-error" @click.native="close($event, 'all')">关闭全部</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>

    <!--    右击下拉框-->
    <el-dropdown ref="rightClickDropdown" class="contextmenu" trigger="click">
      <span style="display:none">11</span>
      <el-dropdown-menu
          slot="dropdown"
          v-show="visible"
          ondragstart="window.event.returnValue=false"
          oncontextmenu="window.event.returnValue=false"
      >
        <el-dropdown-item icon="el-icon-back" @click.native="close($event, 'left')">关闭左侧</el-dropdown-item>
        <el-dropdown-item icon="el-icon-right" @click.native="close($event, 'right')">关闭右侧</el-dropdown-item>
        <el-dropdown-item icon="el-icon-circle-close" @click.native="close($event, 'other')">关闭其他</el-dropdown-item>
        <el-dropdown-item icon="el-icon-error" @click.native="close($event, 'all')">关闭全部</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "demo-fast-nav",
  watch: {
    $route(val) {
      if (val.meta) {
        this.addTab(val.meta, val.name);
      }
    },
    // tabsValue() {
    //   this.jump();
    // },
    "$store.state.container"() {
      this.initVal();
    },
  },
  mounted() {
    this.initVal();
    window.onclick = () => {
      return (() => {
        this.visible = false;
      })();
    };
  },
  data() {
    return {
      tabsValue: "",
      tabs: [],
      visible: false,
    };
  },
  methods: {
    initVal() {
      this.tabs = sessionStorage.getItem("tabs")
          ? JSON.parse(sessionStorage.getItem("tabs"))
          : [
            {
              title: "首页",
              name: "1",
              path: "/console",
            },
          ];
      this.tabsValue = sessionStorage.getItem("tabsValue") || "1";
    },
    getIndex() {
      return this.tabs.map((item) => item.name).indexOf(this.tabsValue);
    },
    getItem(name) {
      return this.tabs.map((item) => item.name).indexOf(name);
    },
    findIndex(target) {
      return this.tabs.findIndex((item) => item.name === target.index) === -1;
    },
    jump() {
      this.$store.state.currentRouterState = 2
      let i = this.getIndex();
      this.$router.push(
          { path: this.tabs[i].path},
          (onComplete) => { },
          (onAbort) => { }
      );
    },
    saveTabs() {
      sessionStorage.setItem("tabs", JSON.stringify(this.tabs));
    },
    saveTabsVal() {
      sessionStorage.setItem("tabsValue", this.tabsValue);
    },
    addTab(target, key) {
      let flag = this.findIndex(target);
      if (flag) {
        let title = target.title.split("/")[target.title.split("/").length - 1];
        this.tabs.push({
          title: title,
          name: target.index,
          path: target.path,
          key: key  //路由key
        });
        this.saveTabs();
      }
      this.tabsValue = target.index;
      this.saveTabsVal();
    },
    close(name, type) {
      this.visible = false;
      try {
        const key = this.tabs.find(item => {
          return item.name == name
        }).key;
        let mainCardElObj =  this.$parent.$children.filter(item => {
          return item.$el.classList.contains("main-card")
        })[0]
        if (mainCardElObj.$children[0].$vnode.parent && mainCardElObj.$children[0].$vnode.parent.componentInstance.cache) {
          let cache = mainCardElObj.$children[0].$vnode.parent.componentInstance.cache  // 缓存的组件
          let delKey = '';
          Object.keys(cache).forEach(keys => {
            if(cache[keys].name == key){
              delKey = keys
            }
          })
          delete cache[delKey]
        }
      } catch (error) {
        console.log('未获取到缓存组件')
      }
      let i = this.getIndex();
      let removeTabs = null;

      if (type === "_this") {
        const j = this.getItem(name);
        removeTabs = this.tabs.splice(j, 1);
        this.tabsValue = this.tabs[j - 1].name;
        this.saveTabsVal();
        this.jump();
      }
      if (type === "left") {
        removeTabs = this.tabs.splice(1, i - 1);
      }
      if (type === "right") {
        removeTabs = this.tabs.splice(i + 1, this.tabs.length - 1);
      }
      if (type === "other") {
        if (this.tabsValue === "1") {
          removeTabs = this.tabs.splice(i + 1, this.tabs.length - 1);
        } else {
          removeTabs = this.tabs.splice(1, i - 1);
          i = 1;
          removeTabs.push(...this.tabs.splice(i + 1, this.tabs.length - 1));
        }
      }
      if (type === "all") {
        removeTabs = this.tabs.splice(1, this.tabs.length - 1);
        this.tabsValue = this.tabs[0].name;
        this.saveTabsVal();
        this.jump();
      }
      this.saveTabs();
      console.log(removeTabs)
      this.$emit("removeTabs",removeTabs)
    },
    show(e) {
      if (this.visible) {
        this.visible = false;
      }
      if (e.srcElement.id) {
        this.tabsValue = e.srcElement.id.replace("tab-", "");
        this.jump();
        this.$refs.rightClickDropdown.$el.style.left =
            e.clientX - (this.$store.state.isCollapse ? 260 : 64) + "px";
        this.visible = true;
        // let timer = setTimeout(() => {
        //   this.visible = true;
        //   clearTimeout(timer)
        // }, 200);
      }
    },
  },
};
</script>

<style lang="scss">
.demo-fast-nav {
  position: relative;
  background-color: #ffffff;
  .is-active {
    background-color: #ffffff;
  }

  .el-tabs__nav {
    border-radius: 0;
  }

  .el-tabs__nav-prev {
    line-height: 28px;
    padding: 0 3px;
    color: #000000;
    background-color: #f0f0f0;
    border-top-left-radius: 2px;
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;

    &:hover {
      background-color: #e4e7ed;
    }
  }

  .el-tabs__nav-next {
    line-height: 28px;
    padding: 0 3px;
    color: #000000;
    background-color: #f0f0f0;
    border-top-right-radius: 2px;
    border-top: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;

    &:hover {
      background-color: #e4e7ed;
    }
  }

  .el-icon-arrow-left {
    font-weight: 900;
  }

  .el-icon-arrow-right {
    font-weight: 900;
  }

  .el-tabs__header {
    margin: 0;
  }

  .el-tabs__item {
    line-height: 30px;
    height: 30px;
  }
  .el-tabs__item:focus.is-active.is-focus:not(:active) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  #tab-1 .el-icon-close {
    display: none;
  }

  .nav-i {
    width: 100%;
    background-color: #f0f0f0;
    color: #000;
    text-align: center;
    line-height: 30px;
    border-radius: 2px;

    &:hover {
      background-color: #e4e7ed;
    }
  }
  .right-click-card {
    position: absolute;
    z-index: 9999;
  }

  //右键菜单
  .contextmenu {
    width: 100%;
    position: absolute;
  }
}
</style>
